<template>
  <!-- <div class="loading-container" v-if="show">
    <div class="loader"></div>
    <div class="tips">正在快马加鞭的加载中....</div>
  </div>-->
  <!-- <Teleport to="body">
    <transition name="el-fade-in-linear">
     
    </transition>
  </Teleport> -->
   <div class="loading-container" v-if="flage">
        <div id="loader">
          <div id="shadow"></div>
          <div id="box"></div>
          <div class="desc-box">{{text}}</div>
        </div>
      </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      flage: false,
      text: "正在快马加鞭的加载中...."
    };
  },
  computed: {
    newData() {
      return store.state.loading;
    },
    newContent() {
      return store.state.content;
    }
  },
  watch: {
    newData(newVal) {
      this.flage = newVal;
    },
    newContent(newVal) {
      this.text = newVal;
    }
  },
  methods: {},
  mounted() {},
  created() {}
};
</script>

<!--
<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}


.tips {
  font-family: "Open Sans";
  color: #52b852;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 55%;
  line-height: 30px;
}
.loader {
  width: 40px;
  aspect-ratio: 0.577;
  clip-path: polygon(0 0, 100% 100%, 0 100%, 100% 0);
  position: relative;
  animation: l19 2s infinite linear;
  overflow: hidden;
  position: relative;
  left: 50%;
  top: 45%;
  margin: 0 0 0 -25px;
}
.loader:before {
  content: "";
  position: absolute;
  inset: -150% -150%;
  background: repeating-conic-gradient(
    from 30deg,
    #ffabab 0 60deg,
    #abe4ff 0 120deg,
    #ff7373 0 180deg
  );
  animation: inherit;
  animation-direction: reverse;
}
@keyframes l19 {
  100% {
    transform: rotate(360deg);
  }
}
</style>
-->
<style scoped lang="less">
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s;
}

#loader {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 40px);
  // background: #000;
}
@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
#box {
  width: 50px;
  height: 50px;
  background: #601986;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

.desc-box {
  width: 200px;
  position: absolute;
  left: -60px;
  top: 70px;
  color: #fff;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// #loader::after {
//    content:v-bind(text);
//    display: block;
//    width: 200px;
//    position: absolute;
//    left: -60px;
//    top: 70px;
//    color:#fff;
// }
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
#shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}

@media (max-width: 1200px) {
  .tips {
    font-family: "Open Sans";
    color: #601986;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 57%;
    line-height: 30px;
    font-weight: bold;
  }
}
</style>