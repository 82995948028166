<template>
  <!-- 主页信息资讯 -->
  <div id="information">
    <div class="box">
      <el-row>
        <el-col :xs="24" :sm="17" :md="17" :lg="17" :xl="17">
          <div>
            <div id="info">
              <h2>公告</h2>
              <div class="box">
                <div class="content" v-for="(item) in noticeList" :key="item.id">
                  <div>
                    <p class="datetxt">{{ item.addTime }}<el-tag class="tag" color="#c78a03" size="mini">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-tag></p>
                  </div>
                  <div @click="toNotice(item)">
                    <p class="text">{{ item.title }}</p>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
            </div>
            <div id="info">
              <h2>资讯</h2>
              <div class="box">
                <div class="content" @click="toInfo(item)" v-for="(item) in infoList" :key="item.id">
                  <div>
                    <p class="datetxt">{{ item.addTime }}<el-tag class="tag" color="#c78a03" size="mini">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-tag></p>
                  </div>
                  <div>
                    <p class="text">{{ item.title }}</p>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
          <div class="photos">
            <div style="cursor: pointer">
              <img class="img" src="../../../public/images/photo09.jpg" alt="" />
            </div>
            <!-- <p style="margin: 0.5rem 0; color: #8c8c8c">主席的话</p> -->
            <img class="img" src="../../../public/images/photo02.jpg" alt="" />
            <img class="img" src="../../../public/images/photo01.jpg" alt="" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HomeInfo from './HomeInfo.vue'
import { getNewsListApi } from '@/api/news/index'
import { Local } from '@/common/cookie'
import dayjs from 'dayjs'
export default {
  components: {
    HomeInfo
  },
  data() {
    return {
      page: 1,
      pageSize: 3,
      noticeList: [],
      infoList: [],
      affiche: {
        title: '公告',
        type: 'affiche',
        contents: [
          { id: 1, date: '2021-2-19', title: '國際音樂考級測評中心考試指南' },
          { id: 2, date: '2021-2-19', title: '國際音樂考級測評中心考級特色' },
          { id: 3, date: '2021-2-19', title: '評審委員會組織架構' }
        ]
      },
      information: {
        title: '考级资讯',
        type: 'info',
        contents: [
          { id: 1, date: '2021-2-19', title: '2021-2023少儿声乐考试大纲已发布！' },
          { id: 2, date: '2021-2-19', title: '2021-2023中提琴考試大綱已发布！' },
          { id: 3, date: '2021-2-19', title: '2021-2023大提琴考試大綱已发布！' }
        ]
      }
    }
  },
  methods: {
    async getNotice() {
      const res = await getNewsListApi(1, this.page, this.pageSize)
      if (res.code == 0) {
        this.noticeList = res.data.data.map((item) => {
          item.addTime = dayjs(item.addTime).format('YYYY-MM-DD')
          return item
        })
      }
    },
    async getInfo() {
      const res = await getNewsListApi(0, this.page, this.pageSize)
      if (res.code == 0) {
        this.infoList = res.data.data.map((item) => {
          item.addTime = dayjs(item.addTime).format('YYYY-MM-DD')
          return item
        })
      }
    },
    toNotice(item) {
      Local.setToken('article', item)
      this.$router.push({ name: 'article', params: { type: 'examtype', id: item.id, data: item } }).catch(err => {
        console.log(err);
      })
    },
    toInfo(item) {
      Local.setToken('article', item)
      this.$router.push({ name: 'article', params: { type: 'information', id: item.id, data: item } }).catch(err => {
        console.log(err);
      })
    }
  },
  created() {
    this.getNotice()
    this.getInfo()
  }
}
</script>

<style scoped>
#info {
  width: 100%;
  padding: 2rem 0 2rem 0;
}
h2 {
  text-indent: 1em;
  margin-bottom: 2rem;
}
.datetxt {
  color: #8c8c8c;
  font-size: 12px;
}
.text {
  margin-top: 12px;
  color: #cb3d06;
  cursor: pointer;
}
.tag {
  margin-left: 1rem;
  display: inline-block;
  color: #fff;
}
.el-divider--horizontal {
  margin: 12px 0;
}
#information {
  width: 100%;
  height: auto;
  background-color: #efefef;
  box-sizing: border-box;
  padding: 1rem 0;
}
.box {
  width: 90%;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0;
  margin: 0 5%;
  height: auto;
}
.photos {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
}
.img {
  width: 100%;
  padding: 5px 0;
}
@media screen and (min-width: 768px) {
  .box {
    width: 100%;
    margin: 0 auto;
    padding: 0 35px 0 35px;
  }
}
</style>