<template>
  <!-- 捕获所有路由中不存在的页面 -->
  <div id="div">
    <h1>警告！你正在访问不存在的页面，点此<a href="/">回到首页</a></h1>
    <!-- <el-button type="primary" @click="asd">阿萨的</el-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: '',
    };
  },
  methods: {
    asd() {
      console.log(this.text);
    }
  },
}
</script>

<style scoped>
#div {
  z-index: 999;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
}

h1 {
  margin-top: 100px;
  text-align: center;
}

a {
  color: #ff0000;
}

</style>
