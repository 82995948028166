<template>
  <div id="mainmenu">
    <div class="box" v-for="(item, index) in boxs" :key="index" @click="toPath(item.path)" @mouseover="over(index)" @mouseout="out(index)">
      <div>
        <img v-img-lazy="item.urlout" class="img" :src="item.urlout" alt="" /><br />
        <span class="text">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxs: [
        { path: '/site', urlout: require('../../../public/images/icon_01.png'), urlover: require('../../../public/images/icon_01-over.png'), name: '考點查詢' },
        { path: '/', urlout: require('../../../public/images/icon_02.png'), urlover: require('../../../public/images/icon_02-over.png'), name: '成績查詢' },
        { path: '/syllabus', urlout: require('../../../public/images/icon_musictheory.png'), urlover: require('../../../public/images/icon_musictheory-over.png'), name: '考試內容' },
        { path: '/onlineSignUp', urlout: require('../../../public/images/icon_03.png'), urlover: require('../../../public/images/icon_03-over.png'), name: '我要報名' },
        { path: '/', urlout: require('../../../public/images/icon_04.png'), urlover: require('../../../public/images/icon_04-over.png'), name: '聯絡客服' }
      ]
    }
  },
  methods: {
    over(e) {
      this.boxs[e].transfer = this.boxs[e].urlout
      this.boxs[e].urlout = this.boxs[e].urlover
      this.color = e
    },
    out(e) {
      this.boxs[e].urlout = this.boxs[e].transfer
    },
    toPath(path) {
      let routeUrl = this.$router.resolve({
        path: path,
        query:{show:true}
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style scoped>
#mainmenu {
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  padding: 0 5% 0 5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box {
  display: inline-block;
  width: 48%;
  height: 145px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 1%;
  padding-top: 1.5rem;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
}
.img {
  height: 68px;
  text-align: center;
}
.text {
  padding: 0 0 5px 20px;
  color: #c78a03;
  font-weight: bold;
  background: url('../../../public/images/icon05.png') no-repeat;
  background-position-y: 30%;
  background-size: 1rem auto;
}
@media screen and (min-width: 768px) {
  #mainmenu {
    padding: 0;
  }
  .box {
    width: 18%;
  }
  .box:hover {
    background-color: #c78a03;
  }
  .box:hover .text {
    color: #fff;
    background: url('../../../public/images/icon05_over.png') no-repeat;
    background-position-y: 30%;
    background-size: 1rem auto;
  }
}
</style>