<template>
  <div id="app">
    <!-- <div id="top"></div> -->
   <Loading />
   <div v-if="true"  class="app-box">
    <div class="clear-both">
    <Header :title="title" :iconUrl="iconUrl"></Header>
    <Breadcumb></Breadcumb>
   </div>
   <div style="flex: 1;background-color:#fef7f0;">
    <transition name="el-fade-in-linear">
      <router-view></router-view>
    </transition>
   </div>
    <Footer :sysCopyrightList="sysCopyrightList" :iconUrl="iconUrl"></Footer>
    <div v-if="showBackToTopButton" @click="backToTop"  class="backtop"></div> 
   </div>

   <div v-else  class="sys-box">
      <systemUpgrade></systemUpgrade>
   </div>
  </div>
</template>

<script>
import { sysCopyrightApi } from '@/api/user/index'
import { Local } from './common/cookie'
import Breadcumb from './components/AllViews/Breadcumb.vue'
import Footer from './components/AllViews/Footer.vue'
import Header from './components/AllViews/Header.vue'
import systemUpgrade from './views/systemUpgrade.vue'

export default {
  data() {
    return {
      showBackToTopButton: false,
      backToTopDistance: 200, // 页面滚动多少像素后显示按钮
      sysCopyrightList:[],
      iconUrl:'',
      title:''
    }
  },
  components: {
    Header,
    Breadcumb,
    Footer,
    systemUpgrade
  },
  methods: {
    handleScroll() {
      this.showBackToTopButton = window.pageYOffset > this.backToTopDistance ? true : false;
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    async getSysCopyright() {
      const res = await sysCopyrightApi()
      if(res.code == 0) {
          this.sysCopyrightList = res.data
          this.iconUrl = res.data[0].icon
          this.title = res.data[0].title
          Local.setToken('url',this.iconUrl)
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.getSysCopyright()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style>
html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  /* filter: grayscale(1); */
}
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fef7f0;   
}

.clear-both::after {
  content: " ";
  display: block;
  clear: both;
}

.app-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sys-box {
  width: 100%;
  height: 100%;
}

.backtop {
  position: fixed;
  right: 0;
  bottom: 40px;
  margin: 0 25px 0 auto;
  display: block;
  width: 54px;
  height: 54px;
  background: url('../public/images/footerTop.png');
  background-size: 100% auto;
  cursor: pointer;
}

Header {
  margin-top: 60px;
}

a {
  text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
