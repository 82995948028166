<script>
import { Local } from '@/common/cookie'
export default {
    props:{
      value:{
        type:Boolean,
        default:false
      }
    },
    data() {
        return {
           
        }
    },
    methods: {
        onConfirm() {
          this.$emit('input',false)
          Local.clear()
          this.$store.commit('setToken', '')
          this.$router.push('/login')
          this.$message({
            type: 'success',
            message: '退出登入成功!'
          })
          
        },
        onCancel() {
            this.$emit('input',false)
        }
    }
}

</script>

<template>
    <transition name="el-fade-in-linear">
        <div class="model" v-if="value" @click="onCancel">
            <div class="pop-box">
                <span>此操作将退出登入, 是否继续?</span>
                <div>
                    <el-button type="primary" @click="onConfirm">确定</el-button>
                    <el-button type="info"  @click="onCancel">取消</el-button>
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped lang="less">
.model {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;

    .pop-box {
        width: 300px;
        height: fit-content;
        min-height: 150px;
        border-radius: 3px;
        background-color: #fff;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        span {
            font-size: 18px;
            color: #333;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>