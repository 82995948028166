import Vue from 'vue'
import VueRouter from 'vue-router'
// 页面
import HomeView from '@/views/HomeView/index.vue'
import NotFoundComponent from '@/views/NotFoundComponentView/index.vue'
import store from '@/store'
import { Local } from '@/common/cookie'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      title: '首页',
      nav:'首页'
    },
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
      nav:'首页'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView/index.vue'),
    meta: {
      nav: '登入',
      title: '',

    },
  },
  {
    path: '/award',
    name: 'award',
    component: () => import('@/views/award.vue'),
    meta: {
      nav: '奖状'
    }
  },
  {
    path: '/examtime',
    name: 'examtime',
    component: () => import('@/views/examTime/index.vue'),
    meta: {
      nav: '考试时间'
    }
  },
  {
    path: '/examareaReg',
    name: 'examareareg',
    component: () => import('@/views/examareaEnroll/index.vue'),
    meta: {
      nav: '承办商认证'
    }
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/userInfo/index.vue'),
    meta: {
      nav: '个人中心'
    }
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('@/views/forgetPwd/index.vue'),
    meta: {
      nav: '忘记密码',
      title: '',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView/index.vue'),
    meta: {
      nav: '关于我们',
      title: '',
    },
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/Information/index.vue'),
    meta: {
      nav: '考级资讯',
      title: '',
    },
  },
  {
    path: '/examtype',
    name: 'examtype',
    component: () => import('@/views/ExamtypeView/index.vue'),
    meta: {
      nav: '考试公告',
      title: '',
    },
  },
  {
    path: '/site',
    name: 'site',
    component: () => import('@/views/SiteView/index.vue'),
    meta: {
      nav: '考点查询',
      title: '',
    },
  },
  {
    path: '/committee',
    name: 'committee',
    component: () => import('@/views/CommitteeView/index.vue'),
    meta: {
      nav: '专家评审',
      title: '',
    },
  },
  {
    path: '/committeedetails/:id',
    name: 'committeedetails',
    component: () => import('@/views/committeedetails/index.vue'),
    meta: {
      nav: '专家评审详情'
    }
  },
  {
    path: '/pwd',
    name: 'pwd',
    component: () => import('@/views/updatePwd/index'),
    meta: {
      nav: '修改密码'
    }
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/PartnersView/index.vue'),
    meta: {
      nav: '合作伙伴',
      title: '',
    },
  },
  {
    path: '/syllabus',
    name: 'syllabus',
    component: () => import('@/views/SyllabusView/index.vue'),
    meta: {
      nav: '考试大纲',
      title: '',
    },
  },
  {
    path: '/article/:type/:id',
    name: 'article',
    component: () => import('@/views//ArticleViews/index.vue'),
    meta: {
      title: '文章',
      nav: '文章'
    },
  },
  {
    path: '/release',
    name: 'release',
    component: () => import('@/views/ScoreRelease/index.vue'),
    meta: {
      nav: '成绩发布'
    }
  },
  {
    path: '/examrelease',
    name: 'examrelease',
    component: () => import('@/views/examRelease/index.vue'),
    meta: {
      nav: '考试项目成绩发布'
    }
  },
  {
    path: '/partners/partnersdetail/:id',
    name: 'partnersdetail',
    component: () => import('@/views/partnersDetail/index.vue'),
    meta: {
      title: '合作伙伴详情',
      nav: '合作伙伴详情'
    }
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: () => import('../views/EnrollView/index.vue'),
    meta: {
      nav: '用户注册',
      title: '用户注册'
    },
  },
  {
    path: '/onlineSignUp',
    name: 'onlineSignUp',
    component: () => import('@/views/onlineApplication/index.vue'),
    meta: {
      nav: '线上报名',
      title: '线上报名'
    },
  },
  {
    path: '/onlineSignUpDetail',
    name: 'onlineSignUpDetail',
    component: () => import('@/views/RegistrationDetails/index.vue'),
    meta: {
      nav: '报名详情',
      title: '报名详情'
    },
  },
  {
    path: '/onlineSignUpList',
    name: 'onlineSignUpList',
    component: () => import('@/views/RegistrationList/index.vue'),
    meta: {
      nav: '报名列表',
      title: '报名列表'
    },
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/components/pay/index.vue'),
    meta: {
      nav: '支付',
      title: '支付'
    },
  },
  // 使用通配符 * 捕获所有未定义的路径
  {
    path: '*',
    component: NotFoundComponent
  }
]

const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes
})

//白名单
const whiteList = ['/home','/login','/forgetPwd','/enroll']
const blackList = ['/enroll','/login','/forgetPwd']
router.beforeEach((to,from,next) => {
  const token = Local.getToken('token')
  //   if(!whiteList.includes(to.path)) {
  //     if(token) {
  //       return next()
  //     }
  //     return next('/login')
  //   }
  //   next()
  if(token && blackList.includes(to.path)) {
     return router.push('/info')
  }
  store.state.path = from.path
  next()
})

export default router
