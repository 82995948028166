
export const lazyPlugin = {
  install(Vue) {
    Vue.directive('img-lazy', {
      inserted: function (el, binding) {
        const df = require('../../public/images/default.png')

        //默认图片
        el.src = df

        const observer = new IntersectionObserver((ob) => {
          // 提供一个回调函数 // 如果 intersectionRatio 为 0，则目标在视野外，大于0 则在视野中
          if (ob[0].intersectionRatio > 0) {
            setTimeout(() => {
              // 替换真实的地址
              el.src = binding.value
              // 已经出现在可视区中则停止监听特定目标元素 避免再次加载
              observer.unobserve(el)
            }, 500)
          }
        })
        // 监听的对象
        observer.observe(el)
      },
    })
  }
}