<template>
  <div class="info-container">
    <h2>{{ title }}</h2>
    <div class="info-box" v-for="(item, index) in contents" :key="index">
      <div class="info-item">
        <div class="time-box">
          <span>{{ item.date }}</span>
          <span class="new-box">新</span>
        </div>
        <div class="info-desc-box">
          <span>
            {{ item.title }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'type', 'contents','noticeList','infoList'],
  methods: {
    goTo(params) {
      this.$router.push({ name: 'article', params: { id: params.id } })
    }
  }
}
</script>

<style scoped lang="less">
.info-container {
  h2 {
    font-size: 28px;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #ccc;

    .info-item {
      width: fit-content;
      padding: 10px;
      margin-bottom: 10px;
    }

    .time-box {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: #8c8c8c;
      margin-bottom: 10px;

      .new-box {
        width: 60px;
        height: 20px;
        background-color: #c78a03;
        border-radius: 40px;
        text-align: center;
        line-height: 20px;
        color: #fff;
      }
    }

    .info-desc-box {
      font-size: 18px;
      color: #cb3d06;
      cursor: pointer;
    }
  }
}
</style>
