<template>
  <!-- 主页 -->
  <div id="home">
    <HomeCarousel/>
    <main01/>
    <main02/>
    <main03/>
  </div>
</template>

<script>
import HomeCarousel from '@/components/HomeView/HomeCarousel.vue'
import main01 from '@/components/HomeView/main01.vue'
import main02 from '@/components/HomeView/main02.vue'
import main03 from '@/components/HomeView/main03.vue'

export default {
  name: 'HomeView',
  components: {
    HomeCarousel,
    main01,
    main02,
    main03
  },
}
</script>
<style scoped>
  /* #home {
    padding-top: 60px;
  }
  @media screen and (min-width:769px) {
    #home {
      padding-top: 0;
    }
  } */
</style>