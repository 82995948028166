<template>
  <div id="div">
    <div v-if="this.$route.name != 'home'" class="mainVisual">
      <div class="bread">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>
            <router-link to="/home" style="color:#c78a03">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link :to="this.$route.path" style="color:#c78a03">{{ this.$route.meta.nav }}</router-link>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  watch: {
    $route() {}
  },
  created() {}
};
</script>

<style scoped>
.mainVisual {
  z-index: 10;
  width: 100%;
  height: 240px;
  background: url("../../../public/images/visual_img_2.png") no-repeat,
    linear-gradient(76deg, #fbfaf6, #ffebde);
  background-position: 50% 80%;
  background-size: 276px auto, 100% auto;
}
.bread {
  z-index: 0;
  box-sizing: border-box;
  width: 100%;
  height: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 80px;
}
a {
  font-weight: 100;
}
@media screen and (min-width: 768px) {
  .mainVisual {
    height: 240px;
    background: url("../../../public/images/visual_img_2.png") no-repeat right
        47px bottom,
      linear-gradient(76deg, #fbfaf6, #ffebde);
  }
  .bread {
    padding-top: 120px;
  }
}
</style>