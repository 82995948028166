<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<template>
  <div class="sys-container">
    <div class="sys-box">
      <img src="../../public/images/sys.jpg" alt="" />
    </div>
  </div>
</template>

<style scoped lang="less">
.sys-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .sys-box {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
